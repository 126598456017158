import React from 'react';
import { graphql } from 'gatsby';

import ContactSection, { ContactSectionVariant } from 'components/molecules/ContactSection';
import FormSection, { FormSectionVariant } from 'components/molecules/FormSection';
import { StyledContactUsWrapper } from 'components/templates/containers';
import Layout from 'components/templates/Layout';

const ContactUsPage = ({
  data: {
    contentfulContactUsPage: { contactSection, formSection, seo },
  },
}) => (
  <Layout seo={seo} isFooterTop={false} bgColor="gray-50" transparentHeader={false}>
    <StyledContactUsWrapper contentWidth={1360} pb={{ base: 110 }}>
      <FormSection {...formSection} formVariant={FormSectionVariant.CONTACT} />
      <ContactSection {...contactSection} contactVariant={ContactSectionVariant.CONTACT} />
    </StyledContactUsWrapper>
  </Layout>
);

export default ContactUsPage;

export const query = graphql`
  query contactUsPageQuery($slug: String) {
    contentfulContactUsPage(slug: { eq: $slug }) {
      seo {
        ...SeoFragment
      }
      contactSection {
        ...ContactFooterStructureFragment
      }
      formSection {
        ...FormSectionStructureFragment
      }
    }
  }
`;
